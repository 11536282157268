import React from 'react'
import CaseItem from './caseItem/caseItem';

import { StaticQuery } from 'gatsby';

const Cases = () => (
    <StaticQuery
        query={graphql`
        query CaseStudyQuery {
            allContentfulCaseStudy {
                edges {
                    node {
                        caseStudyTitile
                        slug
                    }
                }
            }
        }
        `}
        render={data => (
            <div className="Cases">
                <h3>Case Studies</h3>
                <ul>
                    {data.allContentfulCaseStudy.edges.map(({ node }) => (
                        <CaseItem 
                            title={node.caseStudyTitile} 
                            url={node.slug}/>
                    ))}
                </ul>
            </div>
        )}
    />

)

export default Cases