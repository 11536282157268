import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Medium from "../components/medium/medium"
import Cases from "../components/cases/cases"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`UX Design`, `User Experience Design`, `Interaction Design`, `Product Design`, `User Research`, `Product Strategy`, `React`, `ReactJs`, `Rise Interactive`, `Chicago`, `New York`, `UX`, `UI`, `Software`, `Web Apps`, `Freelancer`, `Ryan Brooks`]} />
    <div className="Hero">
      <div className="HeroGroup HomeHeroGroup">
        <h1>Ryan Brooks</h1>
        <p>Senior Interaction Designer at Rise Interactive</p>
        <div className="ContentFeedGroup">
          <Cases />
          <Medium />
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
