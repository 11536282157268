import React from 'react'
import { ExternalLink } from "react-feather"
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const MediumItem = (props) => (
    <li><OutboundLink href={props.url} target="_blank" rel="noopener noreferrer">{props.title} <ExternalLink/></OutboundLink></li>
)

export default MediumItem

