import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import './Header.css'

const NavOptions = styled.div`
  a {
    color: #061338;
    font-weight: 400;
  }
  a:hover {
    text-decoration: underline;
  }
`

const Header = ({ siteTitle }) => (
  <div className="Header">
    <div className="HeaderGroup">
      <Link to="/" className="logo"><img src={require('../../images/logo.svg')} width="70" alt="Ryan B. Designs"/></Link>
      <NavOptions><Link to="/about" activeClassName="active">About Me</Link> | <Link to="/contact" activeClassName="active">Contact</Link></NavOptions>
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
