import React from 'react'
import MediumItem from './mediumItem/mediumItem'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { ExternalLink } from "react-feather"

import { StaticQuery } from 'gatsby';

const Medium = () => (
    <StaticQuery
        query={graphql`
        query MediumQuery {
            allMediumPost(sort: { fields: [createdAt], order: DESC }) {
                edges {
                    node {
                        title
                        uniqueSlug
                    }
                }
            }
        }
        `}
        render={data => (
            <div className="Medium">
                <h3>Recent Articles</h3>
                <ul>
                    {data.allMediumPost.edges.map(({ node }) => (
                        <MediumItem 
                            title={node.title} 
                            url={"https://medium.com/@RyanABrooks/" + node.uniqueSlug}/>
                    ))}
                    <li><OutboundLink href="https://medium.com/@RyanABrooks" target="_blank" rel="noopener noreferrer">Read More <ExternalLink /></OutboundLink></li>
                </ul>
            </div>
        )}
    />

)


export default Medium